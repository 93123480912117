
// FormPage.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import "../App.css";

function FormPage() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    salespersonName: '',
    customerName: '',
    phone: '',
    email: '',
    address: '',
  });

  const [errors, setErrors] = useState({
    salespersonName: '',
    customerName: '',
    phone: '',
    email: '',
    address: '',
  });

  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validateForm = () => {
    const newErrors = {};
    let isValid = true;

    if (!formData.salespersonName.trim()) {
      newErrors.salespersonName = 'Salesperson Name is required';
      isValid = false;
    }

    if (!formData.customerName.trim()) {
      newErrors.customerName = 'Customer Name is required';
      isValid = false;
    }

    if (!formData.phone.trim()) {
      newErrors.phone = 'Phone number is required';
      isValid = false;
    } else if (!/^\d{10}$/.test(formData.phone)) {
      newErrors.phone = 'Phone number must be 10 digits';
      isValid = false;
    }

    if (!formData.email.trim()) {
      newErrors.email = 'Email is required';
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = 'Email is invalid';
      isValid = false;
    }

    if (!formData.address.trim()) {
      newErrors.address = 'Residential Address is required';
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (validateForm()) {
      setIsLoading(true);

      // Pass customer details and cart state when navigating to ProductPage
      setTimeout(() => {
        navigate('/product', { state: { customerDetails: formData } });
        setIsLoading(false);
      }, 1000);
    }
  };

  return (
    <section className="full-page-section">
      <div className="banner">
        <img src="/Banner.png" alt="Banner" className="img" />
        <img src="/Untitled211-rem.png" alt="Logo" className="icon" />
      </div>
      <div className="form-container">
        <h2>Customer Detail Form</h2>

        <form onSubmit={handleSubmit} className="customer-form">
          <div className="form-field">
            <label htmlFor="salespersonName">Salesperson Name</label>
            <input
              type="text"
              id="salespersonName"
              name="salespersonName"
              value={formData.salespersonName}
              onChange={handleChange}
            />
            {errors.salespersonName && <p className="error">{errors.salespersonName}</p>}
          </div>

          <div className="form-field">
            <label htmlFor="customerName">Customer Name</label>
            <input
              type="text"
              id="customerName"
              name="customerName"
              value={formData.customerName}
              onChange={handleChange}
            />
            {errors.customerName && <p className="error">{errors.customerName}</p>}
          </div>

          <div className="form-field">
            <label htmlFor="phone">Phone</label>
            <input
              type="text"
              id="phone"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
            />
            {errors.phone && <p className="error">{errors.phone}</p>}
          </div>

          <div className="form-field">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
            />
            {errors.email && <p className="error">{errors.email}</p>}
          </div>

          <div className="form-field">
            <label htmlFor="address">Residential Address</label>
            <textarea
              id="address"
              name="address"
              value={formData.address}
              onChange={handleChange}
            />
            {errors.address && <p className="error">{errors.address}</p>}
          </div>

          <button type="submit" className="btn-next">
            {isLoading ? 'Loading...' : 'Get a Quote Now'}
          </button>
        </form>
      </div>
    </section>
  );
}

export default FormPage;




















