
// CartPage.js
import React from "react";
import { Container, Row, Col, Card, Button, Image } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { jsPDF } from "jspdf";
import "jspdf-autotable";

const CartPage = () => {
  const { state } = useLocation();
  const cart = state?.cart || [];
  const customerDetails = state?.customerDetails || {};  // Get customer details from state
  const navigate = useNavigate();
                              
  const handleIncrease = (productId) => {
    const updatedCart = cart.map((product) => 
      product.id === productId ? { ...product, quantity: product.quantity + 1 } : product
    );
    navigate("/cart", { state: { cart: updatedCart } });
  };

  const handleDecrease = (productId) => {
    const updatedCart = cart.map((product) => 
      product.id === productId && product.quantity > 1
        ? { ...product, quantity: product.quantity - 1 }
        : product
    );
    navigate("/cart", { state: { cart: updatedCart } });
  };

  const handleRemoveProduct = (productId) => {
    const updatedCart = cart.filter((product) => product.id !== productId);
    navigate("/cart", { state: { cart: updatedCart } });
  };

  const getTotalPrice = () => {
    return cart.reduce((total, product) => total + product.price * product.quantity, 0);
  };

  const handleDownloadPDF = () => {
    const doc = new jsPDF();

    doc.setFontSize(18);
    doc.text("Cart Details", 14, 20);

    // Add Table for Products
    const tableColumn = ["Product Name", "Price (₹)", "Quantity", "Total (₹)"];
    const tableRows = cart.map((product) => [
      product.name,
      product.price,
      product.quantity,
      product.price * product.quantity,
    ]);
    doc.autoTable({ startY: 30, head: [tableColumn], body: tableRows });

    // Add Customer Details
    const finalY = doc.lastAutoTable.finalY + 10;
    doc.text("Customer Details:", 14, finalY);

    const customerInfo = [
      ["Salesperson Name:", customerDetails.salespersonName],
      ["Customer Name:", customerDetails.customerName || "N/A"],
      ["Phone:", customerDetails.phone || "N/A"],
      ["Email:", customerDetails.email || "N/A"],
      ["Address:", customerDetails.address || "N/A"],
    ];

    customerInfo.forEach(([key, value], index) => {
      doc.text(`${key} ${value}`, 14, finalY + (index + 1) * 10);
    });

    // Save PDF
    doc.save("cart-details.pdf");
  };

  return (
    <Container className="mt-5">
      <Row>
        <h2>Your Cart</h2>
        {cart.length === 0 ? (
          <p>No items in the cart</p>
        ) : (
          cart.map((product) => (
            <Col md={4} key={product.id} className="mb-4">
              <Card>
                <Image
                  src={product.image || "default-image.jpg"}
                  alt={product.name}
                  fluid
                  style={{ height: "300px", objectFit: "cover" }}
                />
                <Card.Body>
                  <Card.Title>{product.name}</Card.Title>
                  <Card.Text>
                    Price: ₹{product.price} <br />
                    Quantity: {product.quantity} <br />
                    Total: ₹{product.price * product.quantity}
                  </Card.Text>

                  <Button variant="danger" onClick={() => handleDecrease(product.id)}>-</Button>
                  <Button variant="success" onClick={() => handleIncrease(product.id)} className="ms-2">+</Button>
                  <Button variant="warning" onClick={() => handleRemoveProduct(product.id)} className="ms-2">Remove</Button>
                </Card.Body>
              </Card>
            </Col>
          ))
        )}

        {cart.length > 0 && (
          <Col md={12} className="mt-4">
            <h4>Total Price: ₹{getTotalPrice()}</h4>
            <Button variant="primary" onClick={handleDownloadPDF}>
              Download PDF
            </Button>
          </Col>
        )}

        <Col md={12} className="mt-3">
          <Button variant="secondary" onClick={() => navigate("/product")}>
            Back to Products
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default CartPage;




























