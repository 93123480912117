
import React from "react";
import { Card, Dropdown, DropdownButton, ListGroup } from "react-bootstrap";

const Sidebar = ({ products ,handleFilterCategory, resetFilters, handleFilterColor }) => {
  // Collect unique colors from the products
  const colors = [...new Set(products.map((product) => product.colour))];

  return (
    <Card>
      <Card.Header>
        <h5>Filters</h5>
      </Card.Header>
      <Card.Body>
        {/* Filter by Category (if you add categories in the future) */}
        <h6>Filter by Category</h6>
        <DropdownButton id="dropdown-category" title="Select Category">
          <Dropdown.Item onClick={() => handleFilterCategory(1)}>
            Category 1
            
          </Dropdown.Item>
          <Dropdown.Item onClick={() => handleFilterCategory(2)}>
            Category 2
          </Dropdown.Item>
        </DropdownButton>

        <hr />

        {/* Filter by Color */}
        <h6>Filter by Color</h6>
        <ListGroup horizontal>
          {colors.map((color, index) => (
            <ListGroup.Item
              key={index}
              style={{
                backgroundColor: color ? color.toLowerCase() : "#ccc",
                cursor: "pointer",
                width: "30px",
                height: "30px",
                borderRadius: "50%",
              }}
              onClick={() => handleFilterColor(color)}
            ></ListGroup.Item>
          ))}
        </ListGroup>
      </Card.Body>
    </Card>
  );
};

export default Sidebar;


















