
// ProductPage.js
import React, { useState, useEffect } from "react";
import { Container, Row, Col, Navbar, Button, Badge, Spinner } from "react-bootstrap";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import Sidebar from "./Sidebar";
import ProductCard from "./ProductCard";

const ProductPage = () => {
  const { state } = useLocation();
  const customerDetails = state?.customerDetails || {};  // Get customer details from state
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [cart, setCart] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    axios
     .get("https://agencytik.io/backend/all-data")
      .then((response) => {
        const products = response.data.All_Products || [];
        setProducts(products);
        setFilteredProducts(products); 
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching products:", error);
        setLoading(false);
      });
  }, []);

  const addToCart = (product) => {
    const productInCart = cart.find((item) => item.id === product.id);
    if (productInCart) {
      const updatedCart = cart.map((item) =>
        item.id === product.id ? { ...item, quantity: item.quantity + 1 } : item
      );
      setCart(updatedCart);
    } else {
      setCart([...cart, { ...product, quantity: 1 }]);
    }
  };

  const handleCartClick = () => {
    navigate("/cart", { state: { cart, customerDetails } });  // Pass cart and customer details to CartPage
  };

  const handleFilterCategory = (category) => {
    const filtered = products.filter((product) => product.cat_id === category);
    setFilteredProducts(filtered);
  };

  const handleFilterColor = (color) => {
    const filtered = products.filter((product) => product.colour === color);
    setFilteredProducts(filtered);
  };

  const resetFilters = () => {
    setFilteredProducts(products);
  };

  if (loading) {
    return (
      <div className="loader-container">
        <Spinner animation="border" variant="primary" />
      </div>
    );
  }


  return (
    <Container fluid className="mt-5">
      <Navbar bg="light" variant="light" fixed="top">
        <Navbar.Brand>Product Page</Navbar.Brand>
        <Button variant="outline-primary" onClick={handleCartClick}>
          <i className="bi bi-cart"></i>
          <Badge bg="secondary">{cart.length}</Badge>
        </Button>
      </Navbar>

      <Row>
        <Col md={3}>
          <Sidebar
            products={products}
            handleFilterCategory={handleFilterCategory}
            resetFilters={resetFilters}
            handleFilterColor={handleFilterColor}
          />
        </Col>

        <Col md={9}>
          <Row>
            {filteredProducts.map((product) => (
              <Col md={4} key={product.id} className="mb-4">
                <ProductCard product={product} addToCart={addToCart} />
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default ProductPage;






























