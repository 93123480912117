
import React from "react";
import { Card, Button } from "react-bootstrap";

const ProductCard = ({ product, addToCart }) => {
  const handleAddToCart = () => {
    addToCart(product);
  };

  return (
    <Card>
      <Card.Img
        variant="top"
        src={product.image || "default-image.jpg"} 
        alt={product.name || "Product image"}
      />
      <Card.Body>
        <Card.Title>{product.name || "Unnamed Product"}</Card.Title>
        <Card.Text>
          Price: ₹{product.price} <br />
          Status: {product.status === "1" ? "Available" : "Out of Stock"}
        </Card.Text>
        <div className="d-flex justify-content-between align-items-center">
          <Button
            variant="primary"
            onClick={handleAddToCart}
            className="ms-2"
          >
            Add to Cart
          </Button>
        </div>
      </Card.Body>
    </Card>
  );
};

export default ProductCard;








































